

export default {
    antdMessages: {
        locale: "en-us",
        global: { placeholder: "Please select" },
        Table: {
            filterTitle: "Filter", filterConfirm: "OK", filterReset: "Reset", filterEmptyText: "No filter", filterCheckall: "Select All",
            filterSearchPlaceholder: "Select in result", emptyText: "No data", selectAll: "Select All",
            selectInvert: "Invert select", selectNone: "Clear All", selectionAll: "Select All", sortTitle: "Sort",
            expand: "Expand", collapse: "Clooapse", triggerDesc: "Sort Descend",
            triggerAsc: "Sort Ascend", cancelSort: "Cancel Sort"
        },
        Modal: { okText: "OK", cancelText: "Cancel", justOkText: "OK" },
        Tour: { Next: "Next", Previous: "Previous", Finish: "Finish" },
        Popconfirm: { cancelText: "Cancel", okText: "OK" },
        Transfer: {
            titles: ["", ""],
            searchPlaceholder: "Please input something you want to search",
            itemUnit: "Item",
            itemsUnit: "item",
            remove: "Remove",
            selectCurrent: "select all items in this page",
            removeCurrent: "Remove all items in this page",
            selectAll: "Select All",
            deselectAll: "Deselect All", removeAll: "Remove All", selectInvert: "Invert select this page"
        },
        Upload: {
            uploading: "Uploading",
            removeFile: "Remove",
            uploadError: "Upload failed",
            previewFile: "Preview this file",
            downloadFile: "Download this file"
        }, Empty: { description: "No data" }, Icon: { icon: "Icon" },
        Text: { edit: "Edit", copy: "Copy", copied: "Copied", expand: "Expand", collapse: "Collapse" },
        Form: {
            optional: "(optional)",
            defaultValidateMessages: {
                default: "Field validation failed.",
                required: "Please input this field.",
                enum: "${label}必须是其中一个[${enum}]",
                whitespace: "This field can't be empty.",
                date: { format: "Date format is invalid.", parse: "The value of this field can't convert to date.", invalid: "This field contains a invalid date." },
                string: {
                    len: "This field must be ${len} character(s)",
                    min: "This field at least must have ${min} character(s)",
                    max: "This field up to ${max} character(s)",
                    range: "This field must between ${min} to ${max} character(s)"
                },
                number: {
                    len: "This field must equals ${len}",
                    min: "This field must smaller than ${min}",
                    max: "This field must greater than ${max}",
                    range: "This field must between ${min} to ${max}"
                },
                array: {
                    len: "须为${len}个${label}",
                    min: "最少${min}个${label}",
                    max: "最多${max}个${label}",
                    range: "${label}数量须在${min}-${max}之间"
                },
                pattern: {
                    mismatch: "This field can't pass the value validation."
                }
            }
        },
        Image: { preview: "Preview" }, QRCode: { expired: "QR Code Expired", refresh: "Refresh", scanned: "Scanned" },
        ColorPicker: { presetEmpty: "None" }
    },
    messages: {
        'login.lang-japanese': 'Japanese',
        'login.lang-english': 'English',
        'login.sign-in-to-continue': 'Sign in to continue',
        'login.user-id': 'User ID',
        'login.password': 'Password',
        'login.sign-in-via-sso': 'Sign in via SSO',
        'login.login': 'Login',
        'login.username-required': 'User ID is required.',
        'login.password-required': 'Password is required.',
        'login.login.failed.404000': 'User ID or password incorrect.',
        'sidebar.logout': 'Logout',
        'sidebar.logout-confirm-hint': 'Are you sure to continue?',
        'sidebar.Home': 'Home',
        'sidebar.Assets': 'Assets',
        'sidebar.Projects': 'Project ID',
        'sidebar.Users': 'Users',
        'sidebar.Factory': 'Factory',
        'sidebar.Stocktake': 'Stocktake',
        'home.title': 'OVERVIEW',
        'home.stocktake-schedule': 'Stocktake Schedule',
        'common.status': 'Status',
        'common.thumbnail': 'Thumbnail',
        'common.action': 'Action',
        'common.add': 'Add',
        'common.view': 'View',
        'common.edit': 'Edit',
        'common.import': 'Import',
        'common.import.errors.title': 'Resolve CSV file failed, please check your file format.',
        'common.import.errors.-100001': 'Row {row} with header "{header}" is duplicated.',
        'common.import.errors.-100002': 'Row {row} with header "{header}" format validation failed.',
        'common.import.errors.-100003': 'Row {row} with header "{header}" can\'t be empty.',
        'common.import.errors.-100004': 'Row {row} with header "{header}" too long.',
        'common.import.errors.-100005': 'Row {row} with header "{header}" data type is invalid.',
        'common.import.errors.-200001': 'Header "{header}" was not found.',
        'common.export': 'Export',
        'common.none': '-',
        'common.cancel-select': 'Cancel select',
        'common.active': 'Active',
        'common.inactive': 'Inactive',
        'common.search': 'Search',
        'common.reset': 'Reset',
        'common.remark': 'Remark',
        'common.success': 'Success',
        'common.errors.form-validation-failed.title': 'Form validation failed',
        'common.errors.form-validation-failed.intro': 'Please check your input.',
        'common.errors.internal-error.title': 'Unexcepted error',
        'common.errors.internal-error.intro': 'Server internal error occur. May network issue, or program crashed.',
        'common.errors.add-conflict.title': 'Data add failed',
        'common.errors.add-conflict.intro': 'This ID is existed in database.',
        'common.errors.operation-failed.title': 'Operation failed',
        'import.title': 'Import from file',
        'import.select-file': 'Select File',
        'assets.title': 'ASSETS',
        'assets.add.title': 'Add asset',
        'assets.edit.title': 'Edit asset',
        'assets.view.title': 'Asset details',
        'assets.asset-id': 'Asset ID',
        'assets.scope-io': 'In scope/Out of scope',
        'assets.supplier-asset-id': 'Supplier Asset ID',
        'assets.sap-asset-id': 'Asset',
        'assets.sap-asset-sub-number': 'Sub-Number',
        'assets.orig-asset-id': 'Original Code',
        'assets.line-number': 'Line №',
        'assets.category': 'Category',
        'assets.asset-description': 'Asset Description',
        'assets.label-note': 'Note (Print on label)',
        'assets.remark1': 'Remark 1',
        'assets.remark2': 'Remark 2',
        'assets.asset-class': 'Asset Class',
        'assets.capitalized-on': 'Capitalized On',
        'assets.qty': 'Qty',
        'assets.currency': 'Currency',
        'assets.unit-price': 'Unit Price',
        'assets.ttl-amount': 'Total Amount',
        'assets.useful-life': 'Useful Life',
        'assets.weight': 'Weight (kg)',
        'assets.print-label': 'Print label',
        'assets.export-all-printable': 'Export all data for QR code',
        'assets.export-selected-printable': 'Export selected data for QR code',
        'assets.status.Disposed': 'Disposed',
        'assets.status.In use': 'In use',
        'assets.status.Not in use': 'Not in use',
        'assets.status.Idle': 'Idle',
        'assets.status.Missing': 'Missing',
        'assets.status.Pending Check': 'Pending Check',
        'project.title': 'Project ID',
        'project.add.title': 'Add project',
        'project.edit.title': 'Edit project',
        'project.view.title': 'Project details',
        'project.project-id': 'Project ID',
        'project.jt-jti': 'JT/JTI',
        'users.title': 'USER ID',
        'users.add.title': 'Add user',
        'users.edit.title': 'Edit user',
        'users.view.title': 'User details',
        'users.user-id': 'User ID',
        'users.nickname': 'Nickname',
        'users.password': 'Password',
        'factory.title': 'FACTORIES',
        'factory.add.title': 'Add factory',
        'factory.edit.title': 'Edit factory',
        'factory.view.title': 'Factory details',
        'factory.supplier': 'Supplier',
        'factory.factory': 'Factory',
        'factory.factory-id': 'Factory ID',
        'factory.factory-name': 'Factory Name',
        'factory.factory-address': 'Factory Address',
        'factory.location-data': 'Location Data',
        'stocktake.title': 'STOCKTAKE',
        'stocktake.add': 'Add',
        'stocktake.add.errors.title': 'Stocktake create failed with one or more errors.',
        'stocktake.add.errors.-400000': '{id}: Project ID cannot be empty.',
        'stocktake.add.errors.-400001': '{id}: Stocktake Start Time cannot be empty.',
        'stocktake.add.errors.-400002': '{id}: Stocktake End Time cannot be empty.',
        'stocktake.add.errors.-400003': '{id}: Stocktake Start Time is greater than Stocktake End Time, please check your input.',
        'stocktake.add.errors.-400004': '{id}: Stocktake End Time is smaller than now, please check your input.',
        'stocktake.add.errors.-400005': '{id}: This project still have a stocktake not completed.',
        'stocktake.add.errors.-400006': '{id}: This project contains no any asset, please add one to this project before stocktake.',
        'stocktake.add.errors.-400007': '{id}: This project have no location data currently, please set this field at factories management page.',
        'stocktake.add.errors.-404000': '{id}: The factory is not existed in database, please contact your superior for help.',
        'stocktake.view.title': 'Stocktake details',
        'stocktake.export.title': 'Export stocktake',
        'stocktake.review.title': 'Review stocktake',
        'stocktake.add.title': 'New Stocktake Request',
        'stocktake.stocktake-id': 'Stocktake ID',
        'stocktake.stocktake-date': 'Stocktake Date',
        'stocktake.alert-count': 'Alert Count',
        'stocktake.operator': 'Operator',
        'stocktake.force-cancel': 'Force Cancel',
        'stocktake.force-cancel.title': 'Cancel Stocktake',
        'stocktake.force-cancel.reason': 'Cancel Reason',
        'stocktake.force-cancel.reason-is-empty': 'Reason can not be empty',
        'stocktake.force-cancel.confirm': 'Cancel this Stocktake',
        'stocktake.review': 'Review',
        'stocktake.review.approve': 'Approve',
        'stocktake.review.approve.hint-text': 'Are you sure to approve this stocktake result?',
        'stocktake.review.rollback': 'Rollback',
        'stocktake.review.rollback.hint-text': 'Are you sure to rollback this stocktake?',
        'stocktake.status.Pending': 'Pending',
        'stocktake.status.Active': 'Active',
        'stocktake.status.Ready for Review': 'Ready for Review',
        'stocktake.status.Approved': 'Approved',
        'stocktake.status.Overdue': 'Overdue',
        'stocktake.status.Canceled': 'Canceled',
        'stocktake.asset.previous-image': 'Previous Stocktake',
        'stocktake.asset.current-image': 'Current Stocktake',
        'stocktake.asset.previous-status': 'Previous Status',
        'stocktake.asset.current-status': 'Current Status',
        'stocktake.asset.qty': 'Qty',
        'stocktake.asset.alert-info': 'Alert Info',
        'stocktake.asset.stocktake-login-time': 'Stocktake Login Time',
        'stocktake.asset.bypass-qr-code': 'Bypass QR Code',
        'stocktake.asset.out-of-area': 'Out of Area',
        'stocktake.asset.location-expired': 'Location Expired',
        'stocktake.asset.bypass-qr-code-count': 'Bypass QR Code Count',
        'stocktake.asset.out-of-area-count': 'Out of Area Count',
        'stocktake.asset.location-expired-count': 'Location Expired Count',
    }
}