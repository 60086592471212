import { Outlet } from "react-router-dom";
import { Flex, App } from 'antd';

import React, { useEffect, useState } from 'react'
import type { RequestOptionsType } from '@ant-design/pro-components'
import { IntlProvider } from "react-intl";
import { Locales, Messages } from "./locales/locales";

// Setup ANTD
import { ConfigProvider } from 'antd';
import AntdConfig from './utils/AntdConfig';

export interface TAppContext {
    factoriesBySupplier: RequestOptionsType[];
    setFactoriesBySupplier: React.Dispatch<React.SetStateAction<RequestOptionsType[]>>;

    projectIdsList: {
        label: string,
        value: string,
        title: string,
        key: string,
    }[];
    setProjectIdsList: React.Dispatch<React.SetStateAction<{
        label: string,
        value: string,
        title: string,
        key: string,
    }[]>>;


    isDevMode: boolean;
    setIsDevMode: React.Dispatch<React.SetStateAction<boolean>>;
    language: Locales;
    setLanguage: (language: Locales) => void;
}

export const AppContext = React.createContext<TAppContext>({} as TAppContext);

const Application = () => {
    const magicKeyCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'B', 'A'];
    const [isDevMode, setIsDevMode] = useState<boolean>(false);
    const [keyCodes, setKeyCodes] = useState<string[]>([]);
    const [factoriesBySupplier, setFactoriesBySupplier] = useState<RequestOptionsType[]>([]);
    const [language, setLanguage] = useState<Locales>(Locales.English);
    const [projectIdsList, setProjectIdsList] = useState<{
        label: string,
        value: string,
        title: string,
        key: string,
    }[]>([]);
    useEffect(() => {
        document.onkeyup = (event) => {
            const key = event.key.length === 1 ? event.key.toUpperCase() : event.key;
            keyCodes.push(key);
            if (magicKeyCode.join(',') === keyCodes.join(',')) {
                const pwd = prompt('Password');
                if (pwd !== 'P@s$w0rd') return;
                (navigator as any).isDevMode = true;
                setIsDevMode(true);
                alert('Dev mode enabled');
            }
            const origKeyCodesLength = keyCodes.length;
            setTimeout(() => {
                if (keyCodes.length === origKeyCodesLength) {
                    keyCodes.splice(0, keyCodes.length);
                }
            }, 1000);
        }
        if (localStorage.lastLanguage) {
            setLanguage(localStorage.lastLanguage as Locales);
        }
    }, []);
    const setLanguageWithCache = (language: Locales) => {
        setLanguage(language);
        localStorage.lastLanguage = language;
    }
    return (<IntlProvider messages={Messages[language].messages} locale={language} defaultLocale={Locales.English}>
        <ConfigProvider theme={AntdConfig.theme} locale={Messages[language].antdMessages}>
            <App>
                <AppContext.Provider value={{
                    factoriesBySupplier, setFactoriesBySupplier,
                    projectIdsList, setProjectIdsList,
                    isDevMode, setIsDevMode,
                    language, setLanguage: setLanguageWithCache,
                }}>
                    <Outlet></Outlet>
                </AppContext.Provider>
            </App>
        </ConfigProvider>
    </IntlProvider>);
};

export default Application;
