export const ApiPrefix = '/api/Desktop/';
export const CommonApiPrefix = '/api/Common/';
export const ForceDisableCache = true;

export interface ApiResponse {
    ok: boolean;
    status: number;
    title: string;
}

export interface DataStructBase {
    isDisabled: boolean;
    createTime: string;
    createBy?: string;
    updateTime?: string;
    updateBy?: string;
    remark?: string;
}

export interface SearchParamsBaseModel {
    page?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: 'ASC' | 'DESC';
}

export interface ApiResponseT<T> extends ApiResponse {
    count: number;
    result: T;
}

export interface CsvValidationFailResponse {
    code: number;
    header: string;
    row?: number;
}

const getDefaultRequestInit = (init?: RequestInit) => {
    if (init == null) {
        init = {}
    }
    if (init.headers == null) {
        init.headers = {}
    }
    return init as RequestInit;
}

export const Request = async (url: string, init?: RequestInit): Promise<[(ApiResponse|null), Response]> => {
    const fetchResult = await fetch(ApiPrefix + url, init);
    if (fetchResult.status === 401) {
        window.location.href = '/Login';
    }
    let json: ApiResponse | null = null;
    try {
        json = await fetchResult.json();
    } catch (e) {
        // may convert to json error
    }
    if (json && (json as any).count) {
        (json as any).count = Number((json as any).count);
    }
    return [json, fetchResult];
}

export const RequestBlob = async (url: string, init?: RequestInit): Promise<[(Blob|null), Response]> => {
    const fetchResult = await fetch(ApiPrefix + url, init);
    if (fetchResult.status === 401) {
        window.location.href = '/Login';
    }
    let blob: Blob | null = null;
    try {
        blob = await fetchResult.blob();
    } catch (e) {
        // may convert to json error
    }
    return [blob, fetchResult];
}

export const RequestUrlEncoded = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)['Content-Type'] = 'application/x-www-form-urlencoded';
    return Request(url, lInit);
}

export const RequestJson = async (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)['Content-Type'] = 'application/json';
    return Request(url, lInit);
}

export const DownloadFetch = async (blob: Blob, filename: string) => {
    const fileContents = blob;
    const link = document.createElement("a");
    const url = URL.createObjectURL(fileContents);
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
}

export const downloadURI = (uri: string) => {
    const link = document.createElement("a");
    link.href = uri;
    link.click();
}