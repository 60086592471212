import { Configuration } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    clientId: '99104b56-2943-4bea-9859-f55a294eccab',
    authority: `https://login.microsoftonline.com/705d07a3-2eea-4f3b-ab59-65ca29abeb26`,
    //redirectUri: 'http://localhost:3000',
    //redirectUri: `${window.location.protocol}//${window.location.host}/`,
    redirectUri: `https://jti-ats.com/api/Account/SSOLogin`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export default msalConfig;
