import messagesEnUs from './en-US'
import messagesJaJp from './ja-JP'

export enum Locales {
    English = 'en-US',
    Japanese = 'ja-JP',
}

export const Messages = {
    [Locales.English]: messagesEnUs,
    [Locales.Japanese]: messagesJaJp,
};