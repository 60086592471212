import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom"
import { lazy, Suspense } from 'react'

// Application Base pages
import App from "../App"
import AppBaseContainer from "../pages/AppBaseContainer"
import NotFound from "../pages/NotFound";
import Loading from "../pages/Loading";
import AppIndex from "../pages/AppIndex";

const Login = lazy(() => import('../pages/Login/index'))
const ReactDemo = lazy(() => import('../pages/ReactDemo/ReactDemo'))
const Home = lazy(() => import('../pages/Home/Index'))
const Assets = lazy(() => import('../pages/Assets/Index'))
const Users = lazy(() => import('../pages/Users/Index'))
const Factory = lazy(() => import('../pages/Factory/Index'))
const Stocktake = lazy(() => import('../pages/Stocktake/Index'))
const Projects = lazy(() => import('../pages/Projects/Index'))

// Setup react-router
const router = (() => {

    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={<App />}>
                <Route element={<AppBaseContainer />} >
                    <Route path="/" element={<Suspense fallback={<Loading />}><AppIndex /></Suspense>} />
                    <Route path="/Home" element={<Suspense fallback={<Loading />}><Home /></Suspense>} />
                    <Route path="/ReactDemo" element={<Suspense fallback={<Loading />}><ReactDemo /></Suspense>} />
                    <Route path="/Assets" element={<Suspense fallback={<Loading />}><Assets /></Suspense>} />
                    <Route path="/Users" element={<Suspense fallback={<Loading />}><Users /></Suspense>} />
                    <Route path="/Factory" element={<Suspense fallback={<Loading />}><Factory /></Suspense>} />
                    <Route path="/Stocktake" element={<Suspense fallback={<Loading />}><Stocktake /></Suspense>} />
                    <Route path="/Projects" element={<Suspense fallback={<Loading />}><Projects /></Suspense>} />
                </Route>
                <Route path="/Login" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />
                <Route path="*" element={<NotFound />} />
            </Route>
        )
    );
})();

export default router;