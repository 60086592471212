import SideBar from "../components/SideBar/SideBar";
import { Flex } from 'antd';
import { Outlet } from "react-router-dom";

const Application = () => {
    return (<Flex>
                <div style={{ height: '100vh' }}>
                    <SideBar></SideBar>
                </div>
                <div id="content-container">
                    <Outlet />
                </div>
            </Flex>);
};

export default Application;
