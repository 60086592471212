import { Flex, Avatar, Button, Popconfirm } from 'antd';
import { useNavigate } from "react-router-dom";

import './SideBar.scss';

import IconHome from '../../assets/icon_home.png'
import IconAssets from '../../assets/icon_assets.png'
import IconUsers from '../../assets/icon_user.png'
import IconFactory from '../../assets/icon_factory.png'
import IconStocktake from '../../assets/icon_stocktake.png'
import IconProject from '../../assets/icon_project_active.png'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { GetCurrentUsername, Logout } from '../../utils/api/login'
import { useIntl } from '@ant-design/pro-components';
import { FormattedMessage } from 'react-intl';

const iconSize = 48;

const IconJt = "/assets/JTI_JTI_digital.svg";

export const Links = [
    {
        path: '/Home',
        icon: IconHome,
        title: 'Home',
    },
    {
        path: '/Assets',
        icon: IconAssets,
        title: 'Assets',
        displayPolicy: ['sidebar', 'home'],
    },
    {
        path: '/Projects',
        icon: IconProject,
        title: 'Projects',
        displayPolicy: ['sidebar', 'home'],
    },
    {
        path: '/Users',
        icon: IconUsers,
        title: 'Users',
        displayPolicy: ['sidebar', 'home'],
    },
    {
        path: '/Factory',
        icon: IconFactory,
        title: 'Factory',
        displayPolicy: ['sidebar', 'home'],
    },
    {
        path: '/Stocktake',
        icon: IconStocktake,
        title: 'Stocktake',
        displayPolicy: ['sidebar', 'home'],
    },
    {
        path: '/ReactDemo',
        icon: IconJt,
        title: 'React Demo',
        displayPolicy: [],
    },
];

const App = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const path = window.location.pathname;
    const [username, setUsername] = useState("");

    useEffect(() => {
        (async () => {
            const [result] = await GetCurrentUsername();
            setUsername(result?.result == null ? "" : result?.result);
        })()
    }, []);

    const logout = async () => {
        await Logout();
        navigate('/');
    }
    return (<div className="SideBar">
        <Flex>
            <div className="side-color-block"></div>
            <Flex style={{ flexGrow: 1, height: '100vh', maxHeight: '100vh' }} vertical>
                <Flex style={{ margin: '1.8rem' }} vertical align="center">
                    <div className="circle-logo"><img src={IconJt} width={96} /></div>
                    <div>{username}</div>
                </Flex>
                <Flex className="navigator-area" gap="large" vertical align="end" style={{ flexGrow: 1, flexShrink: 1 }}>
                    {
                        Links.map((val, index) => {
                            if (val.displayPolicy != null && !val.displayPolicy.includes('sidebar')) {
                                return null
                            }
                            return (
                                <Flex
                                    key={index}
                                    className={path === val.path ? 'active' : ''}
                                    onClick={() => navigate(val.path)}
                                    vertical
                                    align="center"
                                >
                                    <div><img src={val.icon} width={44} /></div>
                                    <div><FormattedMessage id={'sidebar.' + val.title} /></div>
                                </Flex>
                            )
                        })
                    }
                </Flex>
                <div style={{ backgroundColor: 'var(--app-primary)', height: '1px', margin: '0.5rem' }}></div>
                <Flex vertical style={{ alignItems: 'center', justifyItems: 'center', margin: '0.5rem 1rem 1rem 1rem' }}>
                    <Popconfirm
                        title={<FormattedMessage id="sidebar.logout" />}
                        description={<FormattedMessage id="sidebar.logout-confirm-hint" />}
                        icon={<QuestionCircleOutlined style={{ color: 'grey' }} />}
                        onConfirm={logout}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link"><FormattedMessage id="sidebar.logout" /></Button>
                    </Popconfirm>
                </Flex>
            </Flex>
        </Flex>
    </div>);
};

export default App