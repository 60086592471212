
export default {
    antdMessages: {
        locale: "ja-JP",
        global: { placeholder: "Please select" },
        Table: {
            filterTitle: "Filter", filterConfirm: "OK", filterReset: "リセット", filterEmptyText: "No filter", filterCheckall: "Select All",
            filterSearchPlaceholder: "Select in result", emptyText: "No data", selectAll: "Select All",
            selectInvert: "Invert select", selectNone: "Clear All", selectionAll: "Select All", sortTitle: "Sort",
            expand: "Expand", collapse: "Clooapse", triggerDesc: "降順",
            triggerAsc: "昇順", cancelSort: "キャンセル"
        },
        Modal: { okText: "OK", cancelText: "Cancel", justOkText: "OK" },
        Tour: { Next: "Next", Previous: "Previous", Finish: "Finish" },
        Popconfirm: { cancelText: "Cancel", okText: "OK" },
        Transfer: {
            titles: ["", ""],
            searchPlaceholder: "Please input something you want to search",
            itemUnit: "Item",
            itemsUnit: "item",
            remove: "Remove",
            selectCurrent: "select all items in this page",
            removeCurrent: "Remove all items in this page",
            selectAll: "Select All",
            deselectAll: "Deselect All", removeAll: "Remove All", selectInvert: "Invert select this page"
        },
        Upload: {
            uploading: "Uploading",
            removeFile: "Remove",
            uploadError: "Upload failed",
            previewFile: "Preview this file",
            downloadFile: "Download this file"
        }, Empty: { description: "No data" }, Icon: { icon: "Icon" },
        Text: { edit: "Edit", copy: "Copy", copied: "Copied", expand: "Expand", collapse: "Collapse" },
        Form: {
            optional: "(optional)",
            defaultValidateMessages: {
                default: "Field validation failed.",
                required: "このフィールドは必要です。",
                enum: "${label}必须是其中一个[${enum}]",
                whitespace: "This field can't be empty.",
                date: { format: "Date format is invalid.", parse: "The value of this field can't convert to date.", invalid: "This field contains a invalid date." },
                string: {
                    len: "This field must be ${len} character(s)",
                    min: "This field at least must have ${min} character(s)",
                    max: "This field up to ${max} character(s)",
                    range: "This field must between ${min} to ${max} character(s)"
                },
                number: {
                    len: "This field must equals ${len}",
                    min: "This field must smaller than ${min}",
                    max: "This field must greater than ${max}",
                    range: "This field must between ${min} to ${max}"
                },
                array: {
                    len: "须为${len}个${label}",
                    min: "最少${min}个${label}",
                    max: "最多${max}个${label}",
                    range: "${label}数量须在${min}-${max}之间"
                },
                pattern: {
                    mismatch: "This field can't pass the value validation."
                }
            }
        },
        Image: { preview: "Preview" }, QRCode: { expired: "QR Code Expired", refresh: "Refresh", scanned: "Scanned" },
        ColorPicker: { presetEmpty: "None" }
    },
    messages: {
        'login.lang-japanese': 'Japanese',
        'login.lang-english': 'English',
        'login.sign-in-to-continue': 'システムログイン',
        'login.user-id': 'User ID',
        'login.password': 'Password',
        'login.sign-in-via-sso': 'Sign in via SSO',
        'login.login': 'Login',
        'login.username-required': 'User ID は必要です。',
        'login.password-required': 'Password は必要です。',
        'login.login.failed.404000': 'User ID または Password が間違っています。',
        'sidebar.logout': 'ログアウト',
        'sidebar.logout-confirm-hint': 'システムからログアウトします',
        'sidebar.Home': 'Home',
        'sidebar.Assets': '棚卸資産',
        'sidebar.Projects': 'Project ID',
        'sidebar.Users': 'User ID',
        'sidebar.Factory': 'Factory',
        'sidebar.Stocktake': '棚卸依頼',
        'home.title': '概要',
        'home.stocktake-schedule': '棚卸依頼の詳細',
        'common.status': '状態',
        'common.thumbnail': '写真',
        'common.action': 'Action',
        'common.add': 'マニュアル入力',
        'common.view': '詳細',
        'common.edit': '編集',
        'common.import': 'ファイル取込み',
        'common.import.errors.title': 'CSVファイルのフォーマットが間違っています',
        'common.import.errors.-100001': 'Row {row} with header "{header}" is duplicated.',
        'common.import.errors.-100002': 'Row {row} with header "{header}" format validation failed.',
        'common.import.errors.-100003': 'Row {row} with header "{header}" can\'t be empty.',
        'common.import.errors.-100004': 'Row {row} with header "{header}" too long.',
        'common.import.errors.-100005': 'Row {row} with header "{header}" data type is invalid.',
        'common.import.errors.-200001': 'Header "{header}" not found.',
        'common.export': 'ファイル出力',
        'common.none': '-',
        'common.cancel-select': '選択の取消',
        'common.active': '有効',
        'common.inactive': '無効',
        'common.search': '検索',
        'common.reset': 'リセット',
        'common.remark': '特記事項',
        'common.success': '成功',
        'common.errors.form-validation-failed.title': 'フォームの検証に失敗しました',
        'common.errors.form-validation-failed.intro': '入力内容を確認してください。',
        'common.errors.internal-error.title': '予想外のエラー',
        'common.errors.internal-error.intro': 'サーバーで内部エラーが発生しました。ネットワークの問題またはプログラムのクラッシュである可能性があります。',
        'common.errors.add-conflict.title': 'データの追加に失敗しました',
        'common.errors.add-conflict.intro': 'このIDはデータベースに存在します。',
        'common.errors.operation-failed.title': '操作失敗',
        'import.title': 'ファイル取込み',
        'import.select-file': 'ファイル選択',
        'assets.title': '棚卸資産',
        'assets.add.title': '棚卸資産の追加',
        'assets.edit.title': '棚卸資産の編集',
        'assets.view.title': '棚卸資産の詳細',
        'assets.asset-id': 'Asset ID',
        'assets.scope-io': '棚卸対象/非対象',
        'assets.supplier-asset-id': 'サプライヤー資産ID',
        'assets.sap-asset-id': '資産番号',
        'assets.sap-asset-sub-number': '補助番号',
        'assets.orig-asset-id': '識別番号（オリジナル）',
        'assets.line-number': 'ライン№',
        'assets.category': 'カテゴリー/工程名称',
        //'assets.asset-description': '発注資産名称',
        'assets.asset-description': '資産概要',
        'assets.label-note': '備考(ラベル印字用)',
        'assets.remark1': '備考1(ラベル印字無し)',
        'assets.remark2': '備考2(ラベル印字無し)',
        'assets.asset-class': '資産クラス',
        'assets.capitalized-on': '資産化日付',
        'assets.qty': '数量',
        'assets.currency': '通貨',
        'assets.unit-price': '単価',
        'assets.ttl-amount': '合計金額',
        'assets.useful-life': '耐用年数',
        'assets.weight': '重量(kg)',
        'assets.print-label': 'ラベル印刷',
        'assets.export-all-printable': 'QRコード印刷用データ全て出力',
        'assets.export-selected-printable': 'QRコード印刷用データ出力',
        'assets.status.Disposed': '廃棄',
        'assets.status.In use': '使用中',
        'assets.status.Not in use': '未使用',
        'assets.status.Idle': '遊休',
        'assets.status.Missing': '失った',
        'assets.status.Pending Check': '検査待ち',
        'project.title': 'PROJECT ID',
        'project.add.title': 'Projectの追加',
        'project.edit.title': 'Projectの編集',
        'project.view.title': 'Projectの詳細',
        'project.project-id': 'Project ID',
        'project.jt-jti': '資産保有先',
        'users.title': 'USER ID',
        'users.add.title': 'Userの追加',
        'users.edit.title': 'Userの編集',
        'users.view.title': 'Userの詳細',
        'users.user-id': 'User ID',
        'users.nickname': 'ニックネーム',
        'users.password': 'Password',
        'factory.title': 'FACTORY',
        'factory.add.title': '工場の追加',
        'factory.edit.title': '工場の編集',
        'factory.view.title': '工場の詳細',
        'factory.supplier': 'Supplier',
        'factory.factory': '工場',
        'factory.factory-id': '貸与先コード',
        'factory.factory-name': '設置場所（会社名/工場名）',
        'factory.factory-address': 'Address',
        'factory.location-data': '位置情報',
        'stocktake.title': '棚卸依頼',
        'stocktake.add': '新規申請',
        'stocktake.add.errors.title': 'Stocktake create failed with one or more errors.',
        'stocktake.add.errors.-400000': '{id}: Project ID cannot be empty.',
        'stocktake.add.errors.-400001': '{id}: Stocktake Start Time cannot be empty.',
        'stocktake.add.errors.-400002': '{id}: Stocktake End Time cannot be empty.',
        'stocktake.add.errors.-400003': '{id}: Stocktake Start Time is greater than Stocktake End Time, please check your input.',
        'stocktake.add.errors.-400004': '{id}: Stocktake End Time is smaller than now, please check your input.',
        'stocktake.add.errors.-400005': '{id}: 選択されたプロジェクトは棚卸が完了していません',
        'stocktake.add.errors.-400006': '{id}: This project contains no any asset, please add one to this project before stocktake.',
        'stocktake.add.errors.-400007': '{id}: This project have no location data currently, please set this field at factories management page.',
        'stocktake.add.errors.-404000': '{id}: The factory is not existed in database, please contact your superior for help.',
        'stocktake.view.title': '棚卸依頼の詳細',
        'stocktake.export.title': '棚卸依頼の出力',
        'stocktake.review.title': '棚卸依頼のレビュー',
        'stocktake.add.title': '新規棚卸依頼の申請',
        'stocktake.stocktake-id': 'Stocktake ID',
        'stocktake.stocktake-date': '棚卸予定日',
        'stocktake.alert-count': 'アラート総数',
        'stocktake.operator': '登録者',
        'stocktake.force-cancel': '依頼のキャンセル',
        'stocktake.force-cancel.title': '棚卸依頼のキャンセル',
        'stocktake.force-cancel.reason': 'キャンセル理由',
        'stocktake.force-cancel.reason-is-empty': 'キャンセル理由を入力して下さい',
        'stocktake.force-cancel.confirm': 'キャンセルこの棚卸依頼',
        'stocktake.review': 'レビュー',
        'stocktake.review.approve': '承認',
        'stocktake.review.approve.hint-text': '本当に承認してもよろしいですか？',
        'stocktake.review.rollback': 'Rollback',
        'stocktake.review.rollback.hint-text': 'Are you sure to rollback this stocktake?',
        'stocktake.status.Pending': '申請中',
        'stocktake.status.Active': 'アクティブ',
        'stocktake.status.Ready for Review': 'レビュー準備完了',
        'stocktake.status.Approved': '承認済',
        'stocktake.status.Overdue': '期限切れ',
        'stocktake.status.Canceled': 'キャンセル',
        'stocktake.asset.previous-image': '前回の写真',
        'stocktake.asset.current-image': '今回の写真',
        'stocktake.asset.previous-status': '前回の使用状況',
        'stocktake.asset.current-status': '今回の使用状況',
        'stocktake.asset.qty': '数量',
        'stocktake.asset.alert-info': 'アラート',
        'stocktake.asset.stocktake-login-time': '棚卸開始日時',
        'stocktake.asset.bypass-qr-code': 'QRコード読取スキップ',
        'stocktake.asset.out-of-area': '登録位置範囲外',
        'stocktake.asset.location-expired': '作業時間オーバー',
        'stocktake.asset.bypass-qr-code-count': 'QRコード読取スキップ合計',
        'stocktake.asset.out-of-area-count': '登録位置範囲外合計',
        'stocktake.asset.location-expired-count': '作業時間オーバー合計',
    }
}